import React, { useState } from "react";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import AvailableAppointment from "./availableAppointments";


const LocationProvider = () => {
    const [IsDateVisibile, setIsDateVisable] = useState(false);
    const [startDate, setStartDate] = useState(new Date());


    const handleCalender = () => {
        setIsDateVisable(prev => !prev)
    }
    const handleDateSelect = () => { }
    const handleDateChange = () => { }
    return (
        <>
            <div className="locaion-provider">
                <div className="title-container">
                    {/* <span className="font-bold fill-tag">
                        Please fill out the information below to schedule an appointment
                    </span> */}
                </div>
                <div className="section">
                    <div className="section-name">
                        Location and Provider
                    </div>
                    <div className="section-divider"></div>
                </div>
                <div className="app-details">
                    <div className="appt-details-main container">
                        <div className="flex-row d-flex flex-wrap">
                            <div className="col-12 col-sm-6 col-md-4">
                                <div className="visit-type-container">
                                    <div className="input-label">
                                        <img src={"https://z4-rpw.phreesia.net/selfscheduling.app/assets/images/visit-type.svg"} style={{ width: 30, height: 30 }} />
                                        <span className="label-text">Visit type</span>
                                    </div>
                                    <div className="phreesia-search-input-container">
                                        <div className="pseudo-drop-down">
                                            <select class="form-select" aria-label="Default select example">
                                                <option selected>New</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                                <div className="visit-type-container">
                                    <div className="input-label">
                                        <img src={"https://z4-rpw.phreesia.net/selfscheduling.app/assets/images/location.svg"} style={{ width: 30, height: 30 }} />
                                        <span className="label-text">Service</span>
                                    </div>
                                    <div className="phreesia-search-input-container">
                                        <div className="pseudo-drop-down">
                                            <select class="form-select" aria-label="Default select example">
                                                <option selected>Psychiatry</option>

                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                                <div className="visit-type-container">
                                    <div className="input-label">
                                        <img src={"https://z4-rpw.phreesia.net/selfscheduling.app/assets/images/provider.svg"} style={{ width: 30, height: 30 }} />
                                        <span className="label-text">Therapy</span>
                                    </div>
                                    <div className="phreesia-search-input-container">
                                        <div className="pseudo-drop-down">
                                            <select class="form-select" aria-label="Default select example">
                                                <option selected>Vinod Bhandari</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex-row next-row buttons-row mt-4">
                            <div className="col-12 col-sm-6 col-md-4" >
                                <button type="button" class="btn background-dark-blue color-white w-100 clear">Clear</button>
                            </div>
                        </div>
                        <div className="slot-search-days-container">
                            <div className="section">
                                <div className="section-name"> Available appointments </div>
                                <div className="section-divider"></div>
                                <button type="button" 
                                className="calendar-wide-screen-btn d-lg-block btn color-orange font-bold border-oval-orange hover-orange background-white" 
                                onClick={handleCalender}>
                                    {IsDateVisibile ? "Hide calendar" : "View calendar"}
                                </button>

                            </div>
                            {
                                <AvailableAppointment />
                            }
                        </div>
                    </div>

                </div>

            </div>
            {
                IsDateVisibile &&
                <div className="calender-display">
                    <LocalizationProvider dateAdapter={AdapterDayjs} className="calender">
                        <DateCalendar />
                    </LocalizationProvider>
                </div>
            }
        </>
    );
};

export default LocationProvider;