import React from "react";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import "./membersDropdown.scss";

const MembersDropdown = () => {
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };
    return (
        <div className="members-dropdown">
            <div className="col-sm">
                <Box sx={{ minWidth: 120 }} className="location-select">
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Location</InputLabel>
                        <Select
                            className="location"
                            labelId="demo-simple-select-label"
                            id="location"
                            value={age}
                            label="Location"
                            onChange={handleChange}
                        >
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </div>
            <div className="col-sm">
                <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Service</InputLabel>
                        <Select
                            className="location"
                            labelId="demo-simple-select-label"
                            id="location"
                            value={age}
                            label="Location"
                            onChange={handleChange}
                        >
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </div>
            <div className="col-sm">
                <Box sx={{ minWidth: 120 }} className="today-select">
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Today</InputLabel>
                        <Select
                            className="location"
                            labelId="demo-simple-select-label"
                            id="location"
                            value={age}
                            label="Location"
                            onChange={handleChange}
                        >
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </div>
        </div>
    );
}


export default MembersDropdown;