import React from "react"
import Header from "../../components/header/header";
import "./payWithInsurance.scss";

const PayWitInsurance = () => {
    return (
        <div className="pay-insurance">
            <div className="header-booking-doc">
                <Header />
            </div>
            <div className="insurance">
                <div className="container-fluid css-qs8l1l es1maqa0">
                    <div className="columns is-multiline is-centered">
                        <div className="column is-8-tablet is-6-desktop is-5-fullhd">
                            <div role="main">
                                <section className="css-1bogmwi eb7amyj1">
                                    <div className="columns is-gapless is-vcentered is-mobile">
                                        <div className="column is-one-third">
                                            <a role="button" id="steps-back" tabIndex="0" className="css-xd0j1g emky6ke1">
                                                <img
                                                    src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTJweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIxNiAxMiAxMiAyMCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDx0aXRsZT44MUM4REUxMC1DM0YyLTRBMkYtQTM3NS1EMTUwMDdBNjVFRDI8L3RpdGxlPgogICAgPGcgaWQ9Ik1vYmlsZS1XZWItSW5zdXJhbmNlLUZsb3ciIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSIzMC4wMy4wMC0tLUluc3VyYW5jZS1Gb3JtLUV4cGFuZGVkIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNi4wMDAwMDAsIC0xNjIuMDAwMDAwKSIgc3Ryb2tlPSIjMDA3NUU0IiBzdHJva2Utd2lkdGg9IjIiPgogICAgICAgICAgICA8ZyBpZD0iUGF0aC0yIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg2LjAwMDAwMCwgMTYyLjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPHBvbHlsaW5lIHBvaW50cz0iMjUuNzUgMTQuNSAxOC4yNSAyMiAyNS43NSAyOS41Ij48L3BvbHlsaW5lPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4K"
                                                    alt="back"
                                                    className="css-1yft4at emky6ke0"
                                                />
                                            </a>
                                            <span className="css-1i576z1 emky6ke2">2 of 6</span>
                                        </div>
                                        <div className="column"></div>
                                    </div>
                                </section>
                                <section className="css-xz1kas eaqa45v0">
                                    <h1 className="css-1ruqnf0 e1vx28xt0">Let's help you find the lowest cost option.</h1>
                                    <h2 className="css-bq7cnj ei1rvit0">Who's your insurance provider?</h2>
                                    <div data-testid="fullscreen-row" className="css-41a6pe e10x3dyf2">
                                        <div className="css-9tx5ga e10x3dyf1">
                                            <a href="#" className="css-t2ye36 e10x3dyf0">&lt; Back</a>
                                        </div>
                                        <div className="el4da70 css-rcr16b-container">
                                            <span id="react-select-9-live-region" className="css-7pg0cj-a11yText"></span>
                                            <span
                                                aria-live="polite"
                                                aria-atomic="false"
                                                aria-relevant="additions text"
                                                role="log"
                                                className="css-7pg0cj-a11yText"
                                            ></span>
                                            <div className="react-async-select__control css-13cymwt-control">
                                                <div className="react-async-select__value-container css-hlgwow">
                                                    <div
                                                        className="react-async-select__placeholder css-1jqq78o-placeholder"
                                                        id="react-select-9-placeholder"
                                                    >
                                                        Search (e.g. Anthem)
                                                    </div>
                                                    <div className="react-async-select__input-container css-19bb58m" data-value="">
                                                        <input
                                                            className="react-async-select__input"
                                                            autoCapitalize="none"
                                                            autoComplete="off"
                                                            autoCorrect="off"
                                                            id="react-select-9-input"
                                                            spellCheck="false"
                                                            tabIndex="0"
                                                            type="text"
                                                            aria-autocomplete="list"
                                                            aria-expanded="false"
                                                            aria-haspopup="true"
                                                            aria-label="Insurance Search"
                                                            role="combobox"
                                                            aria-activedescendant=""
                                                            aria-describedby="react-select-9-placeholder"
                                                            value=""
                                                            style={{
                                                                color: 'inherit',
                                                                background: '0px center',
                                                                opacity: 1,
                                                                width: '100%',
                                                                gridArea: '1 / 2',
                                                                font: 'inherit',
                                                                minWidth: '2px',
                                                                border: '0px',
                                                                margin: '0px',
                                                                outline: '0px',
                                                                padding: '0px',
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="react-async-select__indicators css-1wy0on6"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="css-1fttcpj e1v9kpko8">
                                        <button
                                            type="button"
                                            className="primary-pair-button is-disabled css-jgn4uk e12r4e830"
                                            disabled
                                            style={{ margin: '5px 0px' }}
                                        >
                                            Continue
                                        </button>
                                        <button
                                            type="button"
                                            className="primary-pair-button css-s4dwsg e12r4e830"
                                            style={{ margin: '5px 0px' }}
                                        >
                                            Skip insurance
                                        </button>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
                );

            </div>
        </div>

    )

};

export default PayWitInsurance;