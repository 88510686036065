import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const successToast = (message) => toast.success(message, {
    theme: "colored",
});

export const errorToast = (message) => toast.error(message,
    {
        theme: "colored",
    }
);
