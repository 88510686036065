import React from 'react';
import Header from '../components/header/header';
import MembersDropdown from './membersDropdown';
import "./members.scss"
import Therapists from './psychiatrists';

const Members = () => {
    return (
        <div className="members">
            <div className="members-header">
                <Header />
            </div>
            <div className="members-container">
                <MembersDropdown />
                <Therapists />
            </div>
        </div>
    )
}

export default Members;