import React from "react";
import Header from "../components/header/header";
import DummyImage from "../../assets/img/dummy-img2.jpeg";
import IOS from "../../assets/img/ios-btn.png";
import Andriod from "../../assets/img/android-btn.png";
import Footer from "../components/footer/footer";
import "./whatIcanUseForIT.scss";

const WhatIsUse = () => {
    return(
        <div className="what-can-i-use">
            <div className="what-can-i-use-header-about-doc">
                <Header />
            </div>
            <div className="top-banner inside-banner">
                <div className="container">
                    <div className="tb-content">
                        <h2>
                            Healthcare that <br />works for you
                        </h2>

                    </div>
                </div>
            </div>
            {/* how it works */}
            <div className="preventative-care" style={{paddingTop: 0}}>
                <div className="container inside-container inside-center">
                    <div className="row" style={{marginBottom: 20}}>
                        <div className="col-sm-12">
                            <h2 style={{marginBottom: 10}}>How It Works</h2>
                            <p>Not just a quick fix. For your long-term health.</p>
                        </div>
                    </div>
                    <div className="row clearfix">
                        <div className="col-sm-4">
                            <h3><span className="step step-verti">1</span>Sign Up</h3>
                            <p>Creating an account takes less than a minute.</p>
                        </div>
                        <div className="col-sm-4">
                            <h3><span className="step step-verti">2</span>Start Chatting</h3>
                            <p>Tell us about your concerns and we’ll gather more information about your case.</p>
                        </div>
                        <div className="col-sm-4">
                            <h3><span className="step step-verti">3</span>Talk to a Doctor</h3>
                            <p>Get connected to a licensed doctor who can diagnose and treat your issues, including writing prescriptions.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* book appointment */}
            <div className="container inside-container ins-cont-lft">
                <div className="row clearfix">
                    <div className="col-sm-4 ins-cont-img"><img src={DummyImage} /></div>
                    <div className="col-sm-8 ins-cont-txt">
                        <h3>Book an appointment that's convenient for you.</h3>
                        <p>No matter where you are or how busy your life gets, you can talk to one of our many friendly and knowledgable
                            doctors online at a time that works with your schedule.</p>
                        <a className="button pri-button" >Book an Appointment</a>
                    </div>
                </div>
            </div>
            {/* primary care */}
            <div className="container compare-doc">
                <h3 style={{ textAlign: "center", marginBottom: 40 }}>VirtualPsych is Different Than Traditional Psych</h3>
                <div className="row">
                    <div className="col-sm-12 bd-content" style={{ overflowX: "auto" }}>

                    </div>
                </div>
            </div>
            {/* app banner */}
            <div className="app-bx">
                <div className="app-banner"> </div>
                <div className="container">
                    <div className="app-bnr-cntnt">
                        <h1>Care On The GO</h1>
                        <h4>Download Mobile App</h4>
                        <p>Our mobile app is coming soon! <br />please
                            check in for app release updates.</p>
                        <div className="app-download-link">
                            <a href=""><img src={IOS} alt="" /></a>
                            <a href=""><img src={Andriod} alt="" /></a>
                        </div>
                    </div>
                </div>
            </div>
            {/* footer */}
            <Footer />
            
        </div>
    );
};

export default WhatIsUse;