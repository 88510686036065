import React from "react";
import { useNavigate } from "react-router-dom";
import { Navbar, Container, Nav, NavDropdown, Button } from 'react-bootstrap';
import Logo from "../../../assets/img/virtual-psych-logo.png";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import "./header.scss";

const Header = () => {
    const navigate = useNavigate();
    const routeToMemberLogin = () => {
        navigate("/member-login")
    }

    return (
        <Navbar bg="white" expand="lg" className="main-header navbar">
            <Container>
                <div className="container d-flex justify-content-between">
                    <div className="logo">
                        <Navbar.Brand href="/home" className="d-flex align-items-center">
                            <img src={Logo} alt="logo" />
                        </Navbar.Brand>
                    </div>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <div className="home-button nav justify-content-end">
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Item className="">
                                    <Nav.Link href="/home" className="home">home</Nav.Link>
                                </Nav.Item>
                                <Nav.Item><Nav.Link href="/overview" className="overview">OVERVIEW</Nav.Link></Nav.Item>
                                <Nav.Item><NavDropdown title="members" id="basic-nav-dropdown" className="member">
                                    <NavDropdown.Item href="/how-virtulpsych-works">How does it work?</NavDropdown.Item>
                                    <NavDropdown.Item href="/what-can-i-use-it-for">What can i use it for?</NavDropdown.Item>
                                    <NavDropdown.Item href="/who-are-the-doctors">Who are the doctors?</NavDropdown.Item>
                                </NavDropdown></Nav.Item>
                                <div className="member-login">
                                    <Button id="#emembr-btn" className="membr-btn" variant="memeber-btn" style={{ color: "#fff", fontWeight: 400 }} onClick={routeToMemberLogin}>MEMBER LOGIN</Button>
                                </div>
                                <div className="client-login">
                                    <Button 
                                        id="#emembr-btn" 
                                        variant="memeber-btn" 
                                        style={{ color: "#fff", fontWeight: 400 }} 
                                        type="button"
                                        onClick={(e) => {
                                        e.preventDefault();
                                        window.location.href='https://consumer.scheduling.athena.io/?locationId=20729-1&practitionerId=20729-1';
                                        }}
                                      >BOOK NOW</Button>
                                </div>
                            </Nav>
                        </Navbar.Collapse>
                    </div>
                </div>
            </Container>
        </Navbar>

    );
};

export default Header;