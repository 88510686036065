import React from "react";
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import "./contactus.scss";
import useContactUsHook from "./contactUsHook";
const initialState = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    message: "",
    subject:'',
}
const ContactUs = () => {
    const { 
        formState, 
        formErrors, handleChange, 
        handleBlur,
        handleSubmit, 
        isFormFilled, isLoading,
        onNumberOnlyChange,
        isValidEmail
     } = useContactUsHook(initialState);
  

       
    return (
        <div className="contact-us">
            {/* section 1 */}
            <div className="header-componnent"><Header /></div>
            <div className="container-fluid bg-light">
                <h1 className="text-center text-secondary py-5 display-3">Contact</h1>
            </div>
            {/* section 2 */}
            <div className="container my-5">
                <div className="row">
                    <div className="col-md-4 text-center">
                        <div className="col-md-12"><i id="phone" className="fa fa-phone" aria-hidden="true"
                        ></i></div>
                        <div className="col-md-12 display-4 text-secondary pb-3">Contact Us</div>
                        <div className="col-md-12 info">
                            <h4 className="toll-info" id="info" style={{
                                fontSize: 20,
                                fontWeight: 700,
                                color: "#555",
                            }}>Information (Toll-Free)</h4>
                            <p style={{
                                fontSize: 20,
                                fontWeight: 400,
                            }}> 877.839.4824</p>

                            <h4 style={{
                                fontSize: "20px",
                                fontWeight: 700,
                                color: "#555",
                            }}> Media Contact</h4>
                            <p style={{
                                fontSize: 20,
                                fontWeight: 400
                            }}> info@telewebmd.com</p>

                            <h4 style={{
                                fontSize: "20px",
                                fontWeight: 700,
                                color: "#555",
                            }}> Fax</h4>
                            <p style={{
                                fontSize: 20,
                                fontWeight: 400
                            }}> 833.963.0913</p>

                            <h4 style={{
                                fontSize: "20px",
                                fontWeight: 700,
                                color: "#555",
                            }}> info@virtualpsych.com</h4>
                        </div>
                    </div>
                    <div className="col-md-4 text-center">
                        <div className="col-md-12"><i className="fa fa-location-arrow" aria-hidden="true"
                        ></i></div>
                        <div className="col-md-12 display-4 text-secondary pb-3">Come see us</div>
                        <div className="col-md-12">
                            <h4 style={{
                                fontSize: 20,
                                fontWeight: 700,
                                color: "#555"
                            }}>VirtualPsych</h4>
                            <p style={{
                                fontSize: 20,
                                fontWeight: 400
                            }}>1715 Indian Wood Circle Maumee, OH 43537</p>
                            {/* <BR> */}
                            <h4 style={{
                                fontSize: "20px",
                                fontWeight: 700,
                                color: "#555"
                            }}>Columbus Office</h4>
                            <p style={{
                                fontSize: 20,
                                fontWeight: 400
                            }}>100 East Campus View Boulevard Crosswoods Columbus, OH 43235</p>
                        </div>
                    </div>
                    <div className="col-md-4 text-center">
                        <div className="col-md-12"><i className="fa fa-handshake-o" aria-hidden="true"

                        ></i></div>
                        <div className="col-md-12 display-4 text-secondary pb-3">Support</div>
                        <div className="col-md-12">
                            <h4 style={{
                                fontSize: "20px",
                                fontWeight: 700,
                                color: "#555"
                            }}>Text us at 614-502-3491</h4>
                            <h4 style={{
                                fontSize: "20px",
                                fontWeight: 700,
                                color: "#555"
                            }}>Call us at 614-502-3491</h4>
                            <p style={{
                                fontSize: 20,
                                fontWeight: 400,
                            }}>support@virtualpsych.com</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--  section 3 --> */}
            <section className="" style={{
                backgroundColor: "#9CE",
                background: "linear-gradient(#9CE, #69C)"
            }}>
                <div className="container">
                    <div className="pt-5">
                        <h1 className=" text-center text-white display-4">Contact Us </h1>
                        <h5 className="text-white text-center"> Fill out the form below and we'll get back to you shortly.</h5>
                    </div>
                </div>

                <div className="container my-3">
                    <form action="" method="">
                        <div className="row">
                            <div className="col-md-6">
                                <input name="first_name"
                                    placeholder="First name"
                                    value={formState.first_name}
                                    onChange={handleChange}
                                    onBlur={(e) => handleBlur(e, "first_name", "First Name")}
                                    type="text" className="first-name" />
                                {formState?.first_name?.length <= 0 ?
                                    <small id="passwordHelp" class="text-danger">
                                        {formErrors.first_name}
                                    </small> : ""}
                            </div>
                            <div className="col-md-6">
                                <input
                                    name="last_name"
                                    value={formState.last_name}
                                    placeholder="Last name" type="text" className=""
                                    onChange={handleChange}
                                    onBlur={(e) => handleBlur(e, "last_name", "Last Name")}
                                />
                                {formState?.last_name?.length <= 0 ?
                                    <small id="passwordHelp" class="text-danger">
                                        {formErrors.last_name}
                                    </small> : ""}
                            </div>

                            <div className="col-md-6">
                                <input
                                    id="email" name="email" placeholder="Business email" type="email" className=""
                                    value={formState.email}
                                    onChange={handleChange}
                                    onBlur={(e) => handleBlur(e, "email", "Email")}
                                />
                                {!isValidEmail || formState?.email?.length <= 0 ?
                                    <small id="passwordHelp" class="text-danger">
                                        {formErrors.email}
                                    </small> : ""}
                            </div>
                            <div className="col-md-6">
                                <input
                                    value={formState.phone}
                                    onChange={handleChange}
                                    onBlur={(e) => handleBlur(e, "phone", "Phone")}
                                    // onBlur={(e) => validateField("phone", e)}
                                   
                                    name="phone" 
                                    placeholder="Phone" 
                                    type="tel" className=""
                                    onKeyDown={onNumberOnlyChange}
                                    maxlength="10"
                                    required />

                                {formState.phone?.length <= 0 ?
                                    <small id="passwordHelp" class="text-danger">
                                        {formErrors.phone}
                                    </small> : ""} </div>
                            <div className="col-md-6">
                                <select
                                    value={formState.subject}
                                    onChange={handleChange}
                                    onBlur={(e) => handleBlur(e, "subject", "Subject")}
                                    name="subject" className="" >
                                        <option value="">Please select a subject...</option> 
                                        <option value="support">Support</option>
                                    <option value="careers">Careers</option>
                                    <option value="partners">Partners</option>
                                    <option value="sales">Sales</option>
                                    <option value="other">Other/General Inquiry</option>
                                </select>
                               
                                {formState.subject?.length <= 0 ?
                                    <small id="passwordHelp" class="text-danger">
                                        {formErrors.subject}
                                    </small> : ""}
                            </div>
                            <div className="col-md-12">
                                <textarea
                                    value={formState.message}
                                    onChange={handleChange}
                                    onBlur={(e) => handleBlur(e, "message", "Message")}
                                    name="message"
                                    placeholder="Please enter your message..." rows={8} ></textarea>
                                {formState.message?.length <= 0 ?
                                    <small id="passwordHelp" class="text-danger">
                                        {formErrors.message}
                                    </small> : ""}
                            </div>
                            <button type="submit" className="btn btn-submit my-3" onClick={handleSubmit} 
                                disabled={!(isFormFilled) || !(isValidEmail) }>
                                {isLoading ? <div class="align-items-center">
                                    <div class="spinner-border ml-auto" role="status" aria-hidden="true"></div>
                                </div> : "Send"}
                            </button>
                        </div>
                    </form>
                </div>
            </section>

            <Footer />

        </div>
    );
};

export default ContactUs;
