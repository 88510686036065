import Button from '@mui/material/Button';
import "./scheduleDoctorAppointment.scss";

const ScheduleDoctorAppointments = () => {
    return (
        <div className="appointement">
            <div className="doctor-container">
                <div className="doc-img">
                    <img className='doc-image' src="https://media.istockphoto.com/id/1311511363/photo/headshot-portrait-of-smiling-male-doctor-with-tablet.jpg?s=612x612&w=0&k=20&c=w5TecWtlA_ZHRpfGh20II-nq5AvnhpFu6BfOfMHuLMA=" alt="doc" />
                </div>
                <div className="doc-details">
                    <div className='doc-prof-details'>
                        <div className='doc-name-prof'>
                            <div className="doc-name">Jessica Glassman, LCSW</div>
                            <div className="doc-prof">Mental Health Talk Therapy Team</div>
                        </div>
                        <div className='doc-date-day'>
                            <div className='doc-date'><b>Today</b> Sep 4</div>
                            <div className='doc-day'>(PDT) Pacific Daylight Time</div>
                        </div>


                    </div>
                    <div className="doc-timings row">
                        <Button variant="contained" className='timings-btn mt-2'>8:30Am</Button>
                        <Button variant="contained" className='timings-btn mt-2'>8:30Am</Button>
                        <Button variant="contained" className='timings-btn mt-2'>8:30Am</Button>
                        <Button variant="contained" className='more mt-2'>More</Button>

                    </div>

                </div>

            </div>

        </div>
    );
};

export default ScheduleDoctorAppointments;