import React from "react";
import "./location.scss";

const list = [
    {
        state: "ohio",
        city: [
            "columbus",
            "cleveland",
            "cincinatti",
            "toledo",
            "akron",
            "dayton",
            "youngstown",
            "springfield"
        ]
    },
    {
        state: "michigan",
        city: [
            "detroit",
            "grand rapids",
            "lansing",
            "ann arbor",
            "flint",
            "kalamazoo",
            "battle creek",
            "portage"
        ]
    },
    {
        state: "florida",
        city: [
            "miami",
            "jacksonvile",
            "tampa",            
            "orlando",     
            "lakeland",     
            "st. petersburg",            
            "port st. lucie",  
            "vero beach",            
            "melbourne",
            // "cape coral",  
            // "fort myers",       
            // "pensacola",            
            // "tallahassee",            
            // "fort lauderdale",
            // "gainesville",            
            // "west palm beach",
            // "clearwater",      
            // "ocala",
            // "the villages",            
            // "punta gorda"            
        ]
    }
]
const Locations = () => {
    return (
        <div className="Location-container">
            <div className="location-body">
                <h2>All locations</h2>
                <div className="list">
                    {[...list].map((item, index) => {
                        return (
                            <div className="states-city" key={index}>
                                <div className="states">
                                    <h5 className="state-name">{item?.state}</h5>
                                </div>
                                <div className="city">
                                    {item?.city.map((ele, idx) => {
                                        return <p key={idx}>{ele}</p>
                                    })}
                                </div>
                            </div>
                        )


                    })}
                </div>
            </div>
        </div>
    );
};


export default Locations;