import React from "react";
import "./therapists.scss";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
const Therapists = () => {
    return (
        <div className="therapists">
            <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                        <span className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Therapists
                        </span>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse show d-flex" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div className="accordion-body col-sm-4 d-flex">
                            <img src="https://virtualpsych.com/assets/img/doc-img3.jpg" alt="doctor" className="rounded-circle doc-image" />
                            <div className="doc-details">
                                <h4>Mahesh Tripathi</h4>
                                <p className="exp">DNB-ORTH(9+ years Exp)</p>
                                <span className="client-review">Client Review</span>
                            </div>
                        </div>
                        <div className="timeslots col-sm-8">
                            <div className="slots">
                                <p className="slot-text">Time Slot:</p>
                                <p className="time-date">9th April</p>
                            </div>
                            <Stack spacing={2} direction="row" className="timings-btn">
                                <Button variant="contained" className="time-btn">10:00 AM</Button>
                                <Button variant="contained" className="time-btn">12:30 AM</Button>
                                <Button variant="contained" className="time-btn">01:00: PM</Button>
                                <Button variant="contained" className="time-btn">02:00: PM</Button>
                                <Button variant="contained" className="time-btn">03:00: PM</Button>
                                <Button variant="contained" className="time-btn">10:00: PM</Button>
                                <Button variant="outlined" className="more" endIcon={<ArrowDropDownIcon className="more-icon" />}>More</Button>
                            </Stack>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Therapists;