import React from "react"
import Header from "../../components/header/header";
import "./appointments.scss";


const PlushcareAppointment = () => {
    return (
        <div className="appointment">
            <div className="header-appointment">
                <Header />
                <div className="appointment-insu">
                    <div className="container-fluid css-qs8l1l es1maqa0">
                        <div className="columns is-multiline is-centered">
                            <div className="column is-8-tablet is-6-desktop is-5-fullhd">
                                <div className="columns is-multiline is-centered">
                                    <div className="column is-12-mobile is-12-tablet is-12-desktop">
                                        <section className="css-wiv4hk eb7amyj0">
                                            <div className="columns is-gapless is-vcentered is-mobile">
                                                <div className="column is-one-third">
                                                    <a role="button" id="steps-back" tabIndex="0" className="css-xd0j1g emky6ke1">
                                                        <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTJweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIxNiAxMiAxMiAyMCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDx0aXRsZT44MUM4REUxMC1DM0YyLTRBMkYtQTM3NS1EMTUwMDdBNjVFRDI8L3RpdGxlPgogICAgPGcgaWQ9Ik1vYmlsZS1XZWItSW5zdXJhbmNlLUZsb3ciIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSIzMC4wMy4wMC0tLUluc3VyYW5jZS1Gb3JtLUV4cGFuZGVkIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNi4wMDAwMDAsIC0xNjIuMDAwMDAwKSIgc3Ryb2tlPSIjMDA3NUU0IiBzdHJva2Utd2lkdGg9IjIiPgogICAgICAgICAgICA8ZyBpZD0iUGF0aC0yIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg2LjAwMDAwMCwgMTYyLjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPHBvbHlsaW5lIHBvaW50cz0iMjUuNzUgMTQuNSAxOC4yNSAyMiAyNS43NSAyOS41Ij48L3BvbHlsaW5lPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4K" alt="back" className="css-1yft4at emky6ke0" />
                                                    </a>
                                                    <span className="css-1i576z1 emky6ke2">4 of 6</span>
                                                </div>
                                                <div className="column"></div>
                                            </div>
                                        </section>
                                        <section id="appointments-wrapper" class="appointments-wrapper css-qgd6qg emfkxc70" role="main">
                                            <h1 className="css-1ruqnf0 e1vx28xt0">Visit with a doctor now. Get diagnosed and prescribed Rx.</h1>
                                            <div className="css-1fttcpj e3b28bs5">
                                                <div className="css-jj2l16 e3b28bs4">Filter</div>
                                                <div className="css-yuipzk e3b28bs3">
                                                    <div className="css-1yohjra e3b28bs2">
                                                        <div className="react-datepicker-wrapper">
                                                            <div className="react-datepicker__input-container">
                                                                <button
                                                                    type="button"
                                                                    data-testid="date-picker-input"
                                                                    id="date-picker-input"
                                                                    aria-label="date picker"
                                                                    className="css-10fk7tr e3b28bs7"
                                                                >
                                                                    Date: July 3, 2024 5:22 PM
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="css-yuipzk e3b28bs3">
                                                    <button
                                                        data-toggle="modal"
                                                        data-target="#doctor-modal"
                                                        aria-label="doctor"
                                                        data-testid="doctor_filter"
                                                        type="button"
                                                        className="css-10fk7tr e3b28bs7"
                                                    >
                                                        Doctor: All
                                                    </button>
                                                    <div className="e1wspp6v0 e3vcyft0 css-2rd4fw-container">
                                                        <span id="react-select-4-live-region" className="css-7pg0cj-a11yText"></span>
                                                        <span
                                                            aria-live="polite"
                                                            aria-atomic="false"
                                                            aria-relevant="additions text"
                                                            role="log"
                                                            className="css-7pg0cj-a11yText"
                                                        ></span>
                                                        <div className="react-select__control css-13cymwt-control">
                                                            <div className="react-select__value-container react-select__value-container--has-value css-hlgwow">
                                                                <div className="react-select__single-value css-1dimb5e-singleValue">
                                                                    Patient: Myself
                                                                </div>
                                                                <div className="react-select__input-container css-19bb58m" data-value="">
                                                                    <input
                                                                        className="react-select__input"
                                                                        autoComplete="off"
                                                                        autoCorrect="off"
                                                                        id="react-select-4-input"
                                                                        spellCheck="false"
                                                                        tabIndex="0"
                                                                        type="text"
                                                                        aria-autocomplete="list"
                                                                        aria-expanded="false"
                                                                        aria-haspopup="true"
                                                                        aria-label="patient type"
                                                                        role="combobox"
                                                                        aria-activedescendant=""
                                                                        readOnly=""
                                                                        value=""
                                                                        style={{
                                                                            color: 'inherit',
                                                                            background: '0px center',
                                                                            opacity: 1,
                                                                            width: '100%',
                                                                            gridArea: '1 / 2',
                                                                            font: 'inherit',
                                                                            minWidth: '2px',
                                                                            border: '0px',
                                                                            margin: '0px',
                                                                            outline: '0px',
                                                                            padding: '0px',
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="react-select__indicators css-1wy0on6"></div>
                                                        </div>
                                                    </div>
                                                    <div role="button" className="css-1r5gb7q eowymel0">
                                                        <div role="button" className="css-1wa6ghg e1s9uua41">
                                                            <div className="css-1ubpmw7 e1s9uua40">Language</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="appointment-list css-1d0nbku eaqau3j1">
                                                <div className="css-1wdwtsi emgj1gz0">
                                                    July 3rd 2024

                                                </div>
                                                <div className="appointment css-vi6xve eamk8rf7" data-testid="appointment_item">
                                                    <div className="columns is-mobile is-multiline is-vcentered">
                                                        <div className="column is-narrow">
                                                            <span style={{
                                                                boxSizing: 'border-box',
                                                                display: 'inline-block',
                                                                overflow: 'hidden',
                                                                width: 'initial',
                                                                height: 'initial',
                                                                background: 'none',
                                                                opacity: 1,
                                                                border: '0px',
                                                                margin: '0px',
                                                                padding: '0px',
                                                                position: 'relative',
                                                                maxWidth: '100%'
                                                            }}>
                                                                <span style={{
                                                                    boxSizing: 'border-box',
                                                                    display: 'block',
                                                                    width: 'initial',
                                                                    height: 'initial',
                                                                    background: 'none',
                                                                    opacity: 1,
                                                                    border: '0px',
                                                                    margin: '0px',
                                                                    padding: '0px',
                                                                    maxWidth: '100%'
                                                                }}>
                                                                    <img alt="" aria-hidden="true" src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2792%27%20height=%2792%27/%3e" style={{
                                                                        display: 'block',
                                                                        maxWidth: '100%',
                                                                        width: 'initial',
                                                                        height: 'initial',
                                                                        background: 'none',
                                                                        opacity: 1,
                                                                        border: '0px',
                                                                        margin: '0px',
                                                                        padding: '0px'
                                                                    }} />
                                                                </span>
                                                                <img data-testid="avatar" alt="Dr. Candra Wooten" src="/_next/image/?url=https%3A%2F%2Fstatic.plushcare.com%2Fdoctors%2Fprod%2Fcandrawooten.jpg&amp;w=256&amp;q=75" decoding="async" data-nimg="intrinsic" className="css-1q9rfie em14a0u0" style={{
                                                                    position: 'absolute',
                                                                    inset: '0px',
                                                                    boxSizing: 'border-box',
                                                                    padding: '0px',
                                                                    border: 'none',
                                                                    margin: 'auto',
                                                                    display: 'block',
                                                                    width: '0px',
                                                                    height: '0px',
                                                                    minWidth: '100%',
                                                                    maxWidth: '100%',
                                                                    minHeight: '100%',
                                                                    maxHeight: '100%'
                                                                }} srcSet="/_next/image/?url=https%3A%2F%2Fstatic.plushcare.com%2Fdoctors%2Fprod%2Fcandrawooten.jpg&amp;w=96&amp;q=75 1x, /_next/image/?url=https%3A%2F%2Fstatic.plushcare.com%2Fdoctors%2Fprod%2Fcandrawooten.jpg&amp;w=256&amp;q=75 2x" />
                                                            </span>
                                                        </div>
                                                        <div className="column">
                                                            <div className="columns is-mobile is-multiline is-vcentered">
                                                                <div className="css-1bnkrzn eamk8rf1 column">
                                                                    <p className="css-zdqaz8 eamk8rf5">Dr. Candra Wooten</p>
                                                                    <div className="css-1gj2y34 eamk8rf3">
                                                                        <div data-testid="rating" className="css-1h82gey ec6wh110"></div>
                                                                        <span className="css-8c5eq6 eamk8rf2">4.4</span>
                                                                    </div>
                                                                    <div className="css-9m3rcd eamk8rf6">
                                                                        <span>View profile &amp; 2 reviews</span>
                                                                    </div>
                                                                    <div className="css-1k2ndej eamk8rf4">
                                                                        <svg width="20px" height="20px" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                                            <g id="Mobile-Web-Booking-Flow-Style-Update" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                                <g id="10.02.00---Appointment-List" transform="translate(-137.000000, -682.000000)" stroke="#283779">
                                                                                    <g id="Appt" transform="translate(12.000000, 607.000000)">
                                                                                        <g id="Time" transform="translate(125.000000, 75.000000)">
                                                                                            <path d="M7.5,0.5 C5.5663289,0.5 3.8164322,1.28322891 2.54990641,2.5497547 C1.28317866,3.81648245 0.5,5.56662767 0.5,7.5 C0.5,9.43337233 1.28317866,11.1835175 2.54990641,12.4502453 C3.8164322,13.7167711 5.5663289,14.5 7.5,14.5 C9.43276364,14.5 11.1828847,13.7164759 12.4497563,12.4496043 C13.7164259,11.1829347 14.5,9.4330624 14.5,7.5 C14.5,5.5669376 13.7164259,3.81706526 12.4497563,2.55039568 C11.1828847,1.28352405 9.43276364,0.5 7.5,0.5 Z" id="Stroke-3126"></path>
                                                                                            <polyline id="Stroke-3127" strokeLinecap="round" strokeLinejoin="round" points="7.5 4 7.5 7.49666667 11 11"></polyline>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </svg>
                                                                        <span style={{ paddingLeft: 5 }}>5:45 PM IST</span>
                                                                    </div>
                                                                </div>
                                                                <div className="css-1hplyat eamk8rf0 column is-narrow">
                                                                    <button type="primary" height="40px" className="book-button mobile css-1qxxg33 e12r4e830" style={{ fontSize: '14px' }} tabIndex="-1">
                                                                        Book
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </section>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>

    )

};

export default PlushcareAppointment;