import React from 'react';
import Header from '../components/header/header';
import "./plushcareBooking.scss";

const Booking = () => {
    return (
        <div className='booking'>
            <div className="header-booking-doc">
                <Header />
            </div>
            <div className='booking-steps mt-4'>
                <div className="container-fluid css-qs8l1l es1maqa0">
                    <div className="columns is-multiline is-centered">
                        <div className="column is-8-tablet is-6-desktop is-5-fullhd">
                            <div role="main">
                                <section className="css-1bogmwi eb7amyj1">
                                    <div className="columns is-gapless is-vcentered is-mobile">
                                        <div className="column is-one-third">
                                            <span className="css-1i576z1 emky6ke2">1 of 6</span>
                                        </div>
                                        <div className="column">
                                        </div>
                                    </div>
                                </section>
                                <div className="css-639bzm e18q9v4c0">
                                    <h1 className="css-1ruqnf0 e1vx28xt0">Let's help you find the lowest cost option.</h1>
                                    <div className="css-1fttcpj e18q9v4c1">
                                        <button type="primary"
                                            id="#pay"
                                            className="primary-pair-button css-jgn4uk e12r4e830" style={{
                                                fontSize: "18px",
                                                margin: "5px 0px;"
                                            }} tabindex="-1">Pay with insurance</button>
                                        <button

                                            style={{
                                                fontSize: "18px",
                                                margin: "5px 0px;"
                                            }}

                                            type="primary-tertiary" className="primary-pair-button css-s4dwsg e12r4e830 mt-2" tabindex="-1" >Pay without insurance</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Booking