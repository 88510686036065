import * as React from 'react';
import RenderTabs from './Tabs';

export default function AvailableAppointment() {


const tabLabelsName = ["2023-10-10", "2023-10-11", "2023-10-12", "2023-10-13", "2023-10-14", "2023-10-15", "2023-10-16", "2023-10-17", "2023-10-18", "2023-10-19", "2023-10-20", "2023-10-21"];

  return (
    <>
      <RenderTabs
        tabLabelsName={tabLabelsName}
        tabIndex={0}
      />
    </>
  );
}
