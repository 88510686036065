import React, { useState } from "react";
import { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import { useNavigate } from "react-router-dom";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import "./memberlogin.scss";
import moment from 'moment';

import { errorToast, successToast } from "../../components/toastmessage/toastmessage";
Auth.configure(awsconfig);


const Register = () => {
    const navigate = useNavigate();
    const [inputValues, setInputValues] = useState({
        username: '', password: '', mobilenumber: "",dob: "",gender: "",
    });
    const handleOnChange = event => {
        const { name, value } = event.target;
        setInputValues({ ...inputValues, [name]: value });
    };
    async function signUp(event) {
        event.preventDefault();
        try {
            const user = await Auth.signUp(inputValues?.username, inputValues?.password);
            if (user) {
                successToast("Successfully Created")
                navigate("/home")
            }
        } catch (error) {
            errorToast(error?.message)
            console.error('error signing in', error?.message);
        }
    }
    return (
        <div className="register-here">
            <div className="register-header">
                <Header />
            </div>
            {/* register form */}
            <div id="section3">
                <section id="testimornial-area">
                    <div className="container card main-login-bx">
                        <div className="row">
                            <div className="col-md-6 log-reg-form">
                                <h2>Member/Patient Register Here</h2>
                                <h5>Access board-certified doctors 24/7/365</h5>
                                <form>
                                    <div className="form-group">
                                        <label >Email</label>
                                        <input id="email" type="text" required name="username" className="form-control" placeholder="Email" onChange={handleOnChange} value={inputValues?.username} />
                                    </div>
                                    <div className="form-group">
                                        <label >Password</label>
                                        <input type="password" className="form-control" id="password" required name="password" placeholder="Password" onChange={handleOnChange} value={inputValues?.password} />
                                    </div>
                                    <div className="form-group">
                                        <label >Mobile Number</label>
                                        <input type="text" className="form-control" id="mobilenumber" required name="mobilenumber" placeholder="Mobile Number" onChange={handleOnChange} value={inputValues?.mobilenumber} />
                                    </div>
                                    <div className="form-group">
                                        <label >Gender</label>
                                        <select class="form-select" name="gender" aria-label="Default select example" onChange={handleOnChange} placeholder="Gender">
                                            <option selected>Open this select menu</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label >Date of birth</label>
                                        <input type="date" className="form-control" id="password" required name="dob" placeholder="Date of Birth" onChange={handleOnChange} value={inputValues?.dob}   max={moment().format("YYYY-MM-DD")}/>
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary" onClick={signUp} disabled={
                                            inputValues?.username?.length > 0 && 
                                            inputValues?.password?.length > 0 &&
                                            inputValues?.gender?.length > 0 &&
                                            inputValues?.dob?.length > 0 &&
                                            inputValues?.mobilenumber?.length > 0 
                                            ? false : true}>Register Here</button>
                                    </div>
                                </form >
                            </div >
                            <div className="col-md-6 log-reg-img login-image"></div>
                        </div >
                    </div >
                </section >
            </div >
            <div className="footer mt-5" >
                <Footer />
            </div>
        </div>
    );
};


export default Register;