import React from "react";
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import Doctorpad from "../../assets/img/doctorpad.png";
import Demodoc from "../../assets/img/demo-doc-image.png";
import virtualLogo from "../../assets/img/virtual-psych-logo.png";
import tick from "../../assets/img/tick.svg";
import Ios from "../../assets/img/ios-btn.png"
import Andriod from "../../assets/img/android-btn.png"
import "./overview.scss";

const Overview = () => {
    return (
        <div className="overview">
            <div className="overview-header">
                <Header />
            </div>
            <div className="top-banner inside-banner">
                <div className="container">
                    <div className="tb-content">
                        <h2>
                            See a Psychiatrst Online Today
                        </h2>

                    </div>
                </div>
            </div>
            {/* quality care */}
            <div className="container inside-container ins-cont-lft">
                <div className="row clearfix">
                    <div className="col-sm-4  ins-cont-img">
                        <img src={Doctorpad} /></div>
                    <div className="col-sm-8 ins-cont-txt">
                        <h3>Quality Care. The Time & Attention You Deserve.</h3>

                        <p>VirtualPsych is a completely new approach to Mental Health Care. Our doctors and providers provide
                            individualized treatment, so they can give you the time and care you need. Our service is completely online,
                            which means that you get comprehensive, personalized care from anywhere on any device. Your VirtualPsych doctor
                            is your partner in health, long-term.</p>
                    </div>
                </div>
            </div>
            {/* flexible appointment */}
            <div className="container inside-container ins-cont-rt">
                <div className="row clearfix">
                    <div className="col-sm-4 ins-cont-img">
                        <img src={Demodoc} /></div>
                    <div className="col-sm-8 ins-cont-txt">
                        <h3>Flexible appointments to meet your schedule.</h3>

                        <p>One of the things our clients appreciate the most, beyond access to world-className psychiatric care, is our
                            flexible scheduling. It can take weeks to see a psychiatrist in the office, but with VirtualPsych, you have your
                            first appointment within a short time frame and then enjoy flexible scheduling that fits your life and mental health care
                            needs.</p>

                        <h3>Virtual Care at the highest quality possible.</h3>

                        <p>We provide HD video streaming so you always have a strong connection with your doctor. We’re also happy to book
                            a phone call instead of a video conference call to offer the most convenience and comfort possible. You will
                            never have to deal with poor-quality audio or video connections</p>
                    </div>
                </div>
            </div>
            {/* Individualized Mental Health Care */}
            <div className="grey-container preventative-care">
                <div className="container inside-container inside-center">
                    <div className="row" style={{ marginBottom: "20px" }}>
                        <div className="col-sm-12">
                            <h3 style={{ marginBottom: "10px" }}>Individualized Mental Health Care</h3>
                            <p>A tailored patient-centric approach to mental health treatment.</p>
                        </div>
                    </div>
                    <div className="row clearfix">
                        <div className="col-sm-4">
                            <h4>Register</h4>
                            <p>Registration is simple and we accept a variety of insurance providers.</p>
                        </div>
                        <div className="col-sm-4">
                            <h4>Schedule a visit</h4>
                            <p>Schedule your first appointment within just 24 hours.</p>
                        </div>
                        <div className="col-sm-4">
                            <h4>See a Doctor</h4>
                            <p>Connect with a compassionate psychiatrist by video or phone and get the help you need from the comfort of
                                your home.</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* VirtualPsych is So Different Than Traditional Primary Care */}

            <div className="container compare-doc">
                <h3 style={{ textAlign: "center", marginBottom: "40px" }}>VirtualPsych is a Completely New Platform for treatment of Mental and Behavioral Health</h3>
                
            </div>
            {/* app banner */}
            <div className="app-bx">
                <div className="app-banner"> </div>
                <div className="container">
                    <div className="app-bnr-cntnt">
                        <h1>Care On The GO</h1>
                        <h4>Download Mobile App</h4>
                        <p>Our mobile app is coming soon! <br />please
                            check in for app release updates.</p>
                        <div className="app-download-link">
                            <a href=""><img src={Ios} alt="" /></a>
                            <a href=""><img src={Andriod} alt="" /></a>
                        </div>
                    </div>
                </div>
            </div>
            {/* footer */}
            
            <Footer />
        </div>
    )
};

export default Overview;