import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import Provider from "../provider/provider";
import "./counselors.scss";
import { Navbar, Container, Nav, NavDropdown, Button } from 'react-bootstrap';

const Counselors = () => {
    const styles = {
        minWidth: '150px !important',
        display: "flex",
        alignItems: "center",
        fontWeight: 500,
        padding: '0 24px !important',
        whiteSpace: 'nowrap',
        cursor: 'pointer !important',
        zIndex: 10,
        transition: 'all 0.2s',
        textAlign: 'center',
        textDecoration: 'none',
        fontSize: 18,
        color: "#fff",
        border: " 1px solid #fff !important"
    }

    return (
        <div className="counselors-container">
            <div className="counselors-header">
                <Header />
            </div>
            <section className="counselors-body">
                <section className="lr-hero" id="lr-hero">
                    <div className="container">
                        <div className="lp-hero-content">
                            <h1 className="hero-title">Counselors, Psychiatrists, and Mental Health Services at Thriveworks in Dayton, OH</h1>
                            <h2 className="hero-desc">Meet with a local Thriveworks provider -- we accept insurance & provide personalized, high-quality care</h2>
                            <div className="hero-buttons col">
                                <a className="location-cta-book-btn hero main-location" href="#" style={{
                                    cursor: "pointer",
                                    zIndex: 10,
                                    transition: ' all 0.2s',
                                    textAlign: 'center',
                                    textDecoration: 'none',
                                    minWidth: '150px',
                                    alignItems: 'center',
                                    fontWeight: 500,
                                    padding: ' 0 24px',
                                    whiteSpace: 'nowrap',
                                    fontSize: 18,
                                    background: ' #ffcc40',
                                    borderRadius: 25,
                                    borderColor: '#ffcc40',
                                    color: '#212529',
                                    marginRight: 9,

                                }}>Find a provider</a>
                                <a className="btn rounded-button" href="tel:+1(614) 412-3984" style={{
                                    minWidth: '150px !important',
                                    display: "flex",
                                    alignItems: "center",
                                    fontWeight: 500,
                                    padding: '0 24px !important',
                                    whiteSpace: 'nowrap',
                                    cursor: 'pointer !important',
                                    zIndex: 10,
                                    transition: 'all 0.2s',
                                    textAlign: 'center',
                                    textDecoration: 'none',
                                    fontSize: 18,
                                    color: "#fff",
                                    border: "1px solid #fff",
                                    borderRadius: "20px",
                                }}>(614) 412-3984</a>

                            </div>
                        </div>
                    </div>
                </section>
                <section className="container">
                <div className="lp-second-menu lp-horizontal" id="second-top-menu">
                    <nav className="navbar navbar-expand container" role="navigation">
                        <div id="navbarSupportedContentTwo" className="menu-dayton-oh-container"><ul id="menu-dayton-oh" className="navbar-nav"><li itemscope="itemscope" itemtype="https://www.schema.org/SiteNavigationElement" id="menu-item-152861" className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-152857 current_page_item active menu-item-152861 nav-item"><a title="Dayton, OH" href="https://thriveworks.com/dayton-counseling/" className="top-nav-link nav-link location-nav-link" aria-current="page">Dayton, OH</a></li>
                            <li itemscope="itemscope" itemtype="https://www.schema.org/SiteNavigationElement" id="menu-item-150779" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-150779 nav-item show">
                                <NavDropdown title="Individuals" id="menu-item-150779" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-150779 nav-item show" style={{ color: "blue" }}>
                                    <NavDropdown.Item href="/how-virtulpsych-works">Anxiety Therapy</NavDropdown.Item>
                                    <NavDropdown.Item href="/what-can-i-use-it-for">Depression Counseling</NavDropdown.Item>
                                    <NavDropdown.Item href="/who-are-the-doctors">Psychologists</NavDropdown.Item>
                                </NavDropdown>
                            </li>
                            <li itemscope="itemscope" itemtype="https://www.schema.org/SiteNavigationElement" id="menu-item-150779" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-150779 nav-item show">
                                <NavDropdown title="Relationships" id="menu-item-150779" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-150779 nav-item show">
                                    <NavDropdown.Item href="/how-virtulpsych-works">Couples and Marriage Counseling</NavDropdown.Item>
                                    <NavDropdown.Item href="/what-can-i-use-it-for">Family Therapy</NavDropdown.Item>
                                </NavDropdown>
                            </li>
                            <li itemscope="itemscope" itemtype="https://www.schema.org/SiteNavigationElement" id="menu-item-152916" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-152916 nav-item"><a title="Psychiatry" href="https://thriveworks.com/psychiatry/us/oh/dayton/" className="top-nav-link nav-link location-nav-link">Psychiatry</a></li>
                            <li itemscope="itemscope" itemtype="https://www.schema.org/SiteNavigationElement" id="menu-item-152867" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-152867 nav-item"><a title="Our Providers" href="https://thriveworks.com/dayton-counseling/counselors-therapists/" className="top-nav-link nav-link location-nav-link">Our Providers</a></li>
                            <li itemscope="itemscope" itemtype="https://www.schema.org/SiteNavigationElement" id="menu-item-152869" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-152869 nav-item"><a title="(614) 412-3984" href="tel:+16144123984" className="top-nav-link nav-link location-nav-link">(614) 412-3984</a></li>
                            <li itemscope="itemscope" itemtype="https://www.schema.org/SiteNavigationElement" id="menu-item-168989" className="smiles menu-item menu-item-type-post_type menu-item-object-page menu-item-168989 nav-item"><a title="Online Therapy" href="https://thriveworks.com/dayton-counseling/online-counseling/" className="top-nav-link nav-link location-nav-link">Online Therapy</a></li>
                            <li itemscope="itemscope" itemtype="https://www.schema.org/SiteNavigationElement" id="location-news" className="smiles menu-item menu-item-type-post_type menu-item-object-page nav-item"><a title="News" href="javascript:;" className="nav-link location-nav-link">News</a></li></ul></div>
                    </nav>

                </div>
                <div id="location-content" className="location-content location-page">
                    <section id="lp-location-info" className="container">
                        <div className="row">
                            <h2 className="col heading">Location details</h2>
                        </div>
                    </section>
                    <div className="container d-flex justify-content-between">
                        <div className="col-lg-8">
                            <div id="lp-map">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12271.159379752798!2d-84.1225273!3d39.7443713!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf277b26c6c7f348a!2sThriveworks%20Counseling%20Dayton!5e0!3m2!1sen!2sus!4v1634070663239!5m2!1sen!2sus" width="600" height="313" allowfullscreen=""></iframe></div>
                            <div className="getting-here-wrapper">
                                <p className="getting-title">Getting here</p>
                                <p className="getting-description">Thriveworks Counseling &amp; Psychiatry Dayton is across the street from KFC and Burger King, and right next to Bob Evans. Drive down Linden Avenue, and you will see that we share an office with WIngs of Love Services, among other businesses. </p>
                            </div>
                        </div>
                        {/* sidebar */}

                        <div id="location-sidebar" className=" col-lg-3  location-sidebar ">
                            <div className="lp-phone field">
                                <span className="label">Phone number</span>
                                <a id="lp-phone-value" href="tel:+1(614) 412-3984">
                                    (614) 412-3984</a>
                            </div>

                            <div className="review-summary field">
                                <div className="location-rating"><div className="location-overall-reviews">
                                    <span className="location-text">Ratings </span>
                                    <div className="location-rating-inner">
                                        <span className="location-rating">
                                            <span className="filled">★★★★★</span>
                                        </span>
                                        <span className="location-rating-text">5.00</span>
                                        <span className="location-total-reviews"> (1 reviews)</span>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <div className="lp-address field">
                                <span className="label">Address</span>
                                <span id="lp-address">4130 Linden Ave Suite 145<br />Dayton, OH 45432</span>
                            </div>


                            <div className="lp-address field">
                                <span className="label">Directions</span>
                                <p className="d-flex align-items-center google-link-icon">
                                    <a className="map-directions" rel="noopener" href="https://g.page/r/CYo0f2xssnfyEBA" target="_blank">Google Maps</a>
                                </p>
                            </div>
                            <div className="lp-office-hrs field">
                                <span className="label">Office Hours</span>
                                <span className="label tz">Shown in ET</span>
                                <table>
                                    <tbody><tr className="openingHours">
                                        <td className="day"> Sunday</td>
                                        <td className="time">
                                            8:00am - 9:00pm						</td><td>
                                        </td></tr>
                                        <tr className="openingHours">
                                            <td className="day"> Monday</td>
                                            <td className="time">
                                                8:00am - 9:00pm						</td><td>
                                            </td></tr>
                                        <tr className="openingHours">
                                            <td className="day"> Tuesday</td>
                                            <td className="time">

                                                8:00am - 9:00pm						</td><td>
                                            </td></tr>
                                        <tr className="openingHours">
                                            <td className="day"> Wednesday</td>
                                            <td className="time">
                                                8:00am - 9:00pm						</td><td>
                                            </td></tr>
                                        <tr className="openingHours">
                                            <td className="day"> Thursday</td>
                                            <td className="time">

                                                8:00am - 9:00pm						</td><td>
                                            </td></tr>
                                        <tr className="openingHours">
                                            <td className="day"> Friday</td>
                                            <td className="time">

                                                8:00am - 9:00pm						</td><td>
                                            </td></tr>
                                        <tr className="openingHours">
                                            <td className="day"> Saturday</td>
                                            <td className="time">
                                                8:00am - 9:00pm
                                            </td>
                                            <td>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <hr />
                            <div className="lp-supt-hrs field">
                                <a id="toggle_supt_hrs" href="#support_hours" className="label" data-toggle="collapse">Support team hours<i className="fa fa-angle-down" aria-hidden="true"></i></a>
                                <span className="label tz">Shown in ET</span>
                                <table id="support_hours" className="collapse">
                                    <tbody><tr className="openingHours">
                                        <td className="day"> Sunday</td>
                                        <td className="time">
                                            8:00am - 5:00pm
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                        <tr className="openingHours">
                                            <td className="day"> Monday</td>
                                            <td className="time">
                                                7:00am - 9:30pm
                                            </td>
                                            <td>
                                            </td>
                                        </tr>
                                        <tr className="openingHours">
                                            <td className="day"> Tuesday</td>
                                            <td className="time">
                                                7:00am - 9:30pm
                                            </td>
                                            <td>
                                            </td>
                                        </tr>
                                        <tr className="openingHours">
                                            <td className="day"> Wednesday</td>
                                            <td className="time">
                                                7:00am - 9:30pm
                                            </td>
                                            <td>
                                            </td></tr>
                                        <tr className="openingHours">
                                            <td className="day">
                                                Thursday
                                            </td>
                                            <td className="time">
                                                7:00am - 9:30pm
                                            </td>
                                            <td>
                                            </td>
                                        </tr>
                                        <tr className="openingHours">
                                            <td className="day"> Friday</td>
                                            <td className="time">
                                                7:00am - 9:30pm
                                            </td>
                                            <td>
                                            </td>
                                        </tr>
                                        <tr className="openingHours">
                                            <td className="day"> Saturday</td>
                                            <td className="time">
                                                7:00am - 6:00pm
                                            </td>
                                            <td>
                                            </td>
                                        </tr>
                                    </tbody></table>
                            </div>


                        </div>

                    </div>


                </div>
                <section className="bottom-cta">
                    <div className="container">
                        <div className="row blog-col">
                            <div className="cta-container">
                                <h3>Schedule a session with a mental health professional in Dayton</h3>
                                <p></p><p>Our providers help people make meaningful advances in their lives. We accept most insurances, and offer weekend and evening sessions.</p>
                                <p></p>
                                <div className="bottom-cta-btns">
                                    <a href="https://thriveworks.com/app/providers?state=OH&amp;latitude=39.7589478&amp;longitude=-84.1916069&amp;location=Dayton,%20OH,%20USA&amp;offset=0&amp;limit=10" className="location-cta-book-btn location details main-location">
                                        Find a provider					</a>
                                    <a href="tel:(614) 412-3984" className="cta-call-session"> (614) 412-3984 </a>
                                </div>
                                <img width="112" height="30" src="https://thriveworks.com/wp-content/themes/Thriveworks-Three/assets/img/star-img.png" />
                                <p>Rated 4.5 overall from 10,849 Google reviews</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="insurance-sec">
                    <div className="container">
                        <div className="row">
                            <div className="pricing-insurance">
                                <h2>Pricing &amp; insurance</h2>
                                <p>We accept most insurances and cover 1 in 2 insured Americans across the US. We also offer self-pay rates for those who don't have insurance or aren't covered by their insurance for mental health services. Learn more about <a href="/pricing/">pricing.</a></p>
                                <div className="insurance-list-wrapper">
                                    <ul className="insurance-listing">
                                        <li>Aetna</li>
                                        <li>Anthem Blue Cross and Blue Shield Ohio | BCBS</li>
                                        <li>Anthem Blue Cross Blue Shield of Ohio Medicare Advantage</li>
                                        <li>Blue Cross Blue Shield | Anthem (Blue Card)</li>
                                        <li>Carelon</li>
                                        <li>Cigna | Evernorth</li>
                                        <li>Cigna | Evernorth EAP</li>
                                        <li>Cigna | Evernorth Medicare Advantage</li>
                                        <li>Compsych</li>
                                        <li>First Health Network</li>
                                        <li>Healthsmart</li>
                                        <li>Humana Exchange</li>
                                        <li>Humana HMO/POS</li>
                                        <li>Humana Medicare Advantage</li>
                                        <li>HUMANA PPO/EPO/HDHP</li>
                                        <li>JP Farley Corporation</li>
                                        <li>Medical Mutual</li>
                                        <li>Medical Mutual Medicare Advantage</li>
                                        <li>Medicare OH</li>
                                        <li>Multi Plan PHCS</li>
                                        <li>Poppins Health</li>
                                        <li>Quest Behavioral Health </li>
                                        <li>Six Degrees Health</li>
                                        <li>Tricare East | Out of Network Provider</li>
                                        <li>United Healthcare | Optum</li>
                                        <li>United Healthcare | Optum EAP</li>
                                        <li>UpRise</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="language-sec">
                    <div className="container">
                        <div className="row">
                            <div className="spoken-language">
                                <h2>Languages spoken by providers at this location:</h2>
                                <div className="language-list-wrapper">
                                    <ul className="language-listing">
                                        <li>English</li>                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <hr style={{ marginTop: 80 }} />
                <section className="review-section mt-5" style={{ padding: "0 0 60px" }}>
                    <div className="container">
                        <div className="review-main">
                            <div className="overall-reviews">
                                <span className="text">Client reviews</span>
                            </div>
                            <div className="see-text">
                                <a href="javascript:;" data-toggle="modal" data-target="#allReviewModel" data-review-location="Dayton">See more</a>
                            </div>
                        </div>
                        <div className="why-clients">See why our clients love our providers.
                            <a href="https://search.google.com/local/writereview?placeid=ChIJbxgPg8yFQIgRijR_bGyyd_I" target="_blank" rel="noopener">Leave a review on Google</a>
                        </div>
                        <div className="reviews_wrapper slick-initialized slick-slider" style={{ display: "block;" }}>
                            <div aria-live="polite" className="slick-list draggable">
                                <div className="slick-track" style={{
                                    opacity: 1,
                                    width: "335px",
                                    transform: "translate3d(0px, 0px, 0px)",
                                  
                                }} role="listbox">
                                    <div className="inner-wrapper slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false" style={{
                                        width: "335px"
                                    }} tabindex="-1" role="option" aria-describedby="slick-slide00">
                                        <div className="rating">
                                            <span className="filled">★★★★★</span>
                                        </div>
                                        <div className="reviews">"Thriveworks helped me realize that I do believe people can change. I’m not the person I was three months ago, broken and fearful. I’m healthy an..."
                                            <span className="review-full-text" data-toggle="modal" data-target="#singleReviewModal">Read more</span
                                            >
                                            <span className="addText" style={{ display: "none" }}>"Thriveworks helped me realize that I do believe people can change. I’m not the person I was three months ago, broken and fearful. I’m healthy and happy and for the first time being kind to myself. Thank you for giving me my life back."</span>
                                        </div>
                                        <div className="reviewer">
                                            <span className="client_name">Anonymous Thriveworks Client</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade11 single-review-popup" id="singleReviewModal" tabindex="-1" aria-labelledby="singleReviewModalLabel" style={{ display: "none" }} aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                                <div className="modal-content modal-radius  p-3">
                                    <div className="modal-header border-0">
                                        <div className="modal-title modal-heading pb-0" id="whyTrustModalLabel">Eva's full review
                                        </div>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                    <div className="modal-body pt-0">
                                        <div className="modal-text">
                                        </div>
                                    </div>
                                    <div className="modal-footer border-0 pt-0">
                                        <button type="button" className="btn btn-outline-secondary" data-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div><div className="modal fade11 all-review-popup" id="allReviewModel" tabindex="-1" aria-labelledby="singleReviewModalLabel" style={{ display: "none" }} aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                                <div className="modal-content modal-radius  ">
                                    <div className="modal-header border-0 mx-3 pb-0">
                                        <div className="modal-title modal-heading pb-0" id="whyTrustModalLabel">All client reviews</div>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                    <div className="modal-body pt-0 mt-3 mx-3 mb-1">
                                        <div className="modal-text">
                                            <div className="loader">
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer border-1 pt-0">
                                        <button type="button" className="btn btn-outline-secondary" data-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="lp-services" className="lp-services grey-border-bottom row">
                    {/* <div className="container"> */}
                        <div id="main_services_offered" className="services-wrap">
                            <div className="row">
                                <div className="col-12">
                                    <h4 className="lps heading">Main services offered</h4>
                                    <ol>
                                        <li className="tile col">
                                            <span className="text">Individual Therapy</span>
                                        </li>
                                        <li className="tile col">
                                            <span className="text">In-person &amp; Online Therapy</span>
                                        </li>
                                        <li className="tile col">
                                            <span className="text">Child Therapy &amp; Teen Counseling</span>
                                        </li>
                                        <li className="tile col">
                                            <span className="text">Family Therapy</span>
                                        </li>
                                        <li className="tile col">
                                            <span className="text">Couples Therapy &amp; Marriage Counseling</span>
                                        </li>
                                        <li className="tile col">
                                            <span className="text">Psychiatry / Medication</span>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div id="concerns_services_offered" className="services-wrap">
                            <div className="row">
                                <div className="col-12">
                                    <h4 className="lps heading">Top concerns &amp; issues</h4>
                                    <ol>
                                        <li className="tile col">
                                            <span className="text">Anxiety</span>
                                        </li><li className="tile col">
                                            <span className="text">Depression</span>
                                        </li>
                                        <li className="tile col">
                                            <span className="text">Relationships</span>
                                        </li>
                                        <li className="tile col">
                                            <span className="text">ADHD</span>
                                        </li>
                                        <li className="tile col">
                                            <span className="text">Trauma / PTSD</span>
                                        </li>
                                        <li className="tile col">
                                            <span className="text">Grief / loss</span>
                                        </li>
                                        <li className="tile col">
                                            <span className="text">Addiction</span>
                                        </li>
                                        <li className="tile col">
                                            <span className="text">LGBTQIA+ related issues</span>
                                        </li>
                                        <li className="tile col"
                                        ><span className="text">Eating disorders</span>
                                        </li>
                                        <li className="tile col">
                                            <span className="text">Anger</span>
                                        </li>
                                        <li className="tile col">

                                            <span className="text">Bipolar disorder</span>
                                        </li>
                                        <li className="tile col">
                                            <span className="text">Coping skills</span>
                                        </li>
                                        <li className="tile col">
                                            <span className="text">Stress</span>
                                        </li>
                                        <li className="tile col">
                                            <span className="text">Life transitions</span>
                                        </li>
                                        <li className="tile col">
                                            <span className="text">Postpartum / pregnancy</span>
                                        </li>
                                        <li className="tile col">
                                            <span className="text">Sexual abuse / issues</span>
                                        </li>
                                        <li className="tile col">
                                            <span className="text">Self-esteem</span>
                                        </li>
                                        <li className="tile col">
                                            <span className="text">Sleep / insomnia</span>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div id="counseling_services_offered" className="services-wrap">
                            <div className="row">
                                <div className="col-12">
                                    <h4 className="lps heading">Common counseling approaches</h4>
                                    <ol>
                                        <li className="tile col">
                                            <span className="text">Cognitive Behavioral Therapy (CBT)
                                            </span>
                                        </li>
                                        <li className="tile col">
                                            <span className="text">Dialectical Behavioral Therapy (DBT)</span>
                                        </li>
                                        <li className="tile col">
                                            <span className="text">Christian counseling</span>
                                        </li><li className="tile col">
                                            <span className="text">Eye movement desensitization &amp; reprocessing (EMDR)</span></li>
                                        <li className="tile col">
                                            <span className="text">Play therapy</span>
                                        </li>
                                        <li className="tile col">
                                            <span className="text">Family systems</span>
                                        </li><li className="tile col">
                                            <span className="text">Emotionally-focused therapy</span>
                                        </li>
                                        <li className="tile col">
                                            <span className="text">Mindfulness-based cognitive therapy (MBCT)</span>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    {/* </div> */}
                </section>
                <section id="main-content" className="row grey-border-bottom  loc-content d-flex" style={{ marginTop: 64 }}>
                    <div className="col-lg-4 heading-wrp">
                        <h3 className="heading">Frequently asked questions</h3>
                    </div>
                    <div className="col-lg-8">
                        <article id="post-152857" className="post-152857 page type-page status-publish has-post-thumbnail hentry">
                            <div className="entry-content">
                                <p>Perhaps you’ve had a major change in your life and are now feeling overwhelmed and a high level of distress. Or maybe you can’t pinpoint anything in particular but you’re nonetheless feeling symptoms that are interfering in your day-to-day life.</p>
                                <p>Whatever your case may be, it’s important to take your mental health seriously. The television show <em>This Is Us</em> highlights this, following the lives of characters who experience mental illness and seek out support as a result. In the show, one beloved character struggles with severe panic attacks while another suffers from PTSD. The show explores various forms of professional support including individual therapy sessions, group therapy, and more.</p>
                                <p>If you’re not feeling yourself, getting help is the first step toward improving your mental well-being and regaining a higher quality of life. At <span data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Thriveworks Counseling &amp; Psychiatry Dayton&quot;}" data-sheets-userformat="{&quot;2&quot;:515,&quot;3&quot;:{&quot;1&quot;:0},&quot;4&quot;:{&quot;1&quot;:2,&quot;2&quot;:12050893},&quot;12&quot;:0}">Thriveworks Counseling &amp; Psychiatry Dayton</span>, we’re here to help children, adolescents, and adults get their lives back on track.</p>
                                <h2>Should I Consider Therapy?</h2>
                                <p>Mental health services are not just for those with severe problems. Therapy can also help those who are seeking personal growth, support during major life events, and more. Unfortunately, many people shy away from getting help for their problems because it’s frowned upon by their friends and family.</p>
                                <p>Many people grow up with parents and grandparents who feel they don’t need counseling and can just cope on their own. While the research and cultural acceptance surrounding talk therapy and counseling have advanced, this type of thinking nevertheless remains in some subsets of the population.</p>
                                <p>If this kind of upbringing resonates with you, the good news is you are not alone. There are millions just like you who have taken the first step, getting the help they need for the benefit of themselves and those they care about. By working with a trained therapist at <span data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Thriveworks Counseling &amp; Psychiatry Dayton&quot;}" data-sheets-userformat="{&quot;2&quot;:515,&quot;3&quot;:{&quot;1&quot;:0},&quot;4&quot;:{&quot;1&quot;:2,&quot;2&quot;:12050893},&quot;12&quot;:0}">Thriveworks Counseling &amp; Psychiatry Dayton</span>, you can take control of your well-being and navigate your own journey of healing with professional guidance.</p>
                                <p>Some of the most common reasons people reach out for therapy or counseling include:</p>
                                <ul>
                                    <li>Difficult life transitions or decisions</li>
                                    <li>Trauma</li>
                                    <li>Desire for self-discovery</li>
                                    <li>Anxiety</li>
                                    <li>Depression</li>
                                    <li>Addiction</li>
                                    <li>Grief</li>
                                    <li>Personal growth</li>
                                    <li>Eating disorders</li>
                                    <li>Relationship challenges</li>
                                </ul>
                                <p>The benefits you receive from getting help can last you a lifetime. Plus, your therapist, psychiatrist, psychologist, or counselor can work with you to customize your treatment to match your issues, desires, and goals. At <span data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Thriveworks Counseling &amp; Psychiatry Dayton&quot;}" data-sheets-userformat="{&quot;2&quot;:515,&quot;3&quot;:{&quot;1&quot;:0},&quot;4&quot;:{&quot;1&quot;:2,&quot;2&quot;:12050893},&quot;12&quot;:0}">Thriveworks Counseling &amp; Psychiatry Dayton</span>, we can create a therapeutic program that makes sense for you.</p>
                                <h2>Connect With an Online Psychiatrist</h2>
                                <p>Are you suffering from depressive episodes, panic attacks, PTSD, substance abuse, or another mental health issue? Many mental and emotional disorders can impact your physical health as well as your mental health. In these cases, clients tend to benefit from someone who can look at the bigger picture when assessing their symptoms and devising a treatment plan.</p>
                                <p>Licensed psychiatrists are medical doctors, meaning they are able to perform tests to evaluate your physical, mental, and emotional symptoms, and prescribe medication when appropriate. Mental illnesses may be caused by a combination of psychological, physical, and environmental factors. In these cases, a multi-faceted approach involving medication and talk therapy may be necessary.</p>
                                <p>Are you interested in speaking to a licensed psychiatrist with years of experience? Get in touch with us today at Thriveworks in Dayton, OH to schedule a remote appointment.</p>
                                <h2>Schedule an Appointment Today at <span data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Thriveworks Counseling &amp; Psychiatry Dayton&quot;}" data-sheets-userformat="{&quot;2&quot;:515,&quot;3&quot;:{&quot;1&quot;:0},&quot;4&quot;:{&quot;1&quot;:2,&quot;2&quot;:12050893},&quot;12&quot;:0}">Thriveworks Counseling &amp; Psychiatry Dayton</span></h2>
                                <p>Are you struggling with feelings of depression or anxiety? Consider working with a psychiatrist, psychologist, therapist, or counselor at <span data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Thriveworks Counseling &amp; Psychiatry Dayton&quot;}" data-sheets-userformat="{&quot;2&quot;:515,&quot;3&quot;:{&quot;1&quot;:0},&quot;4&quot;:{&quot;1&quot;:2,&quot;2&quot;:12050893},&quot;12&quot;:0}">Thriveworks Counseling &amp; Psychiatry Dayton</span> today. Getting the help you need is private, confidential, and easier than ever before. You can book your appointment online and enjoy a 100% satisfaction guarantee up to one week after your session.</p>
                                <p>Plus, new clients can book an appointment in as little as 24 hours from the time they reach out. We also offer both weekend and evening sessions, as well as telehealth appointments for your convenience.</p>
                                <p>Clients also get access to a host of membership benefits, including:</p>
                                <ul>
                                    <li>Advice by email or phone outside of appointments</li>
                                    <li>24-hour access to our Ask-A-Coach feature</li>
                                    <li>Access to the Therapy Buddy mobile app</li>
                                    <li>Exclusive video content created by psychology experts</li>
                                    <li>An active discussion board moderated by mental health professionals</li>
                                </ul>
                                <p>Are you ready to bring more stability, happiness, and fulfillment into your life? Make an appointment today with the licensed professional counselors at <span data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Thriveworks Counseling &amp; Psychiatry Dayton&quot;}" data-sheets-userformat="{&quot;2&quot;:515,&quot;3&quot;:{&quot;1&quot;:0},&quot;4&quot;:{&quot;1&quot;:2,&quot;2&quot;:12050893},&quot;12&quot;:0}">Thriveworks Counseling &amp; Psychiatry Dayton</span> to get started.</p>
                            </div>


                        </article>
                    </div>
                </section>
                <section id="more-about-wrap" className="row" style={{ marginTop: 64 }}>
                    <div className="col">
                        <h4 className="heading" style={{
                            fontSize: "24px",
                            fontWeight: "500 !important",
                            color: "#212529",
                            paddingBottom: "20px"
                        }}>More about this location</h4>
                        <div className="sub-heading">Explore our services and specialties.</div>
                        <div id="more-about-location" className="more-about-location row">
                            <div id="indiv" className="col-md-4 more-sub-section">
                                <span className="label">Individuals</span>
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-159916">
                                    <a href="https://thriveworks.com/dayton-counseling/anxiety-therapy/" className="top-nav-link ">Anxiety Therapy</a>
                                </li>
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-159904">
                                    <a href="https://thriveworks.com/dayton-counseling/depression-counseling/" className="top-nav-link ">Depression Counseling</a>
                                </li>
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-159909">
                                    <a href="https://thriveworks.com/dayton-counseling/psychologists/" className="top-nav-link ">Psychologists</a>
                                </li></div>
                            <div id="relation" className="col-md-4 more-sub-section">
                                <span className="label">Relationships</span>
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-159923">
                                    <a href="https://thriveworks.com/dayton-counseling/couples-marriage-counseling/" className="top-nav-link ">Couples and Marriage Counseling</a>
                                </li>
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-159930">
                                    <a href="https://thriveworks.com/dayton-counseling/family-therapy/" className="top-nav-link ">Family Therapy</a>
                                </li></div>
                            <div id="misc" className="col-md-4 more-sub-section">
                                <span className="label">Get Started</span>
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-152916"><a href="https://thriveworks.com/psychiatry/us/oh/dayton/" className="top-nav-link ">Psychiatry</a>
                                </li>
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-152867">
                                    <a href="https://thriveworks.com/dayton-counseling/counselors-therapists/" className="top-nav-link ">Our Providers</a>
                                </li>
                                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-152869">
                                    <a href="tel:+16144123984" className="top-nav-link ">(614) 412-3984</a>
                                </li>
                                <li className="smiles menu-item menu-item-type-post_type menu-item-object-page menu-item-168989">
                                    <a href="https://thriveworks.com/dayton-counseling/online-counseling/" className="top-nav-link ">Online Therapy</a>
                                </li>
                            </div>

                        </div>
                        <div id="more-about-nav" className="menu-dayton-oh-container">
                            <ul id="menu-dayton-oh-1" className="menu">
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="bottom-cta">
                    <div className="container">
                        <div className="row blog-col">
                            <div className="cta-container">
                                <h3>Are you struggling?<br />
                                    Thriveworks can help.</h3>
                                <p>
                                </p>
                                <p>Browse top-rated therapists near you, and find one who meets your needs. We accept most insurances, and offer weekend and evening sessions.</p>
                                <p>
                                </p>
                                <div className="bottom-cta-btns">
                                    <a href="https://thriveworks.com/app/providers?state=OH&amp;latitude=39.7589478&amp;longitude=-84.1916069&amp;location=Dayton,%20OH,%20USA&amp;offset=0&amp;limit=10" className="location-cta-book-btn bottom-tail main-location">
                                        Find a provider					</a>
                                    <a href="tel:(614) 412-3984" className="cta-call-session"> (614) 412-3984 </a>
                                </div>
                                <img width="112" height="30" src="https://thriveworks.com/wp-content/themes/Thriveworks-Three/assets/img/star-img.png" />
                                <p>Rated 4.5 overall from 10,849 Google reviews</p>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="inthenews_section location-news">
                    <div class="container">
                        <h4 class="inthenews-head">In the news</h4>
                        <div class="inthenews_wrapper slick-initialized slick-slider">
                            <div aria-live="polite" class="slick-list draggable">
                                <div class="slick-track d-flex" style={{
                                    opacity: 1,
                                    width: "670px",
                                    transform: "translate3d(0px, 0px, 0px)"
                                }} role="listbox">
                                    <div class="news-wrapper slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false" style={{ width: "305px" }} tabindex="-1" role="option" aria-describedby="slick-slide10">
                                        <a href="https://www.dayton.com/lifestyles/social-worker-sees-more-anxiety-and-depression-in-current-health-climate/P2QKC7WDH5DMNL3KU2UH7SQMJY/" rel="noopener" target="_blank" tabindex="0">
                                            <div class="news-heading" style={{
                                                color: "#212529",
                                                fontWeight: 500,
                                                height: "90px",
                                                textOverflow: "ellipsis",
                                              
                                            }}>Social worker sees more anxiety and depression in current health climate</div>
                                            <div class="news-row">
                                                <span class="news-sub-head">Dayton.com</span>
                                                <span class="news-date">7/2/22</span>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="news-wrapper slick-slide slick-active" data-slick-index="1" aria-hidden="false" style={{ width: "305px" }} tabindex="-1" role="option" aria-describedby="slick-slide11">
                                        <a href="https://www.apartmenttherapy.com/sexual-assault-rearranging-bedroom-37067473" rel="noopener" target="_blank" tabindex="0">
                                            <div class="news-heading"
                                            style={{
                                                color: "#212529",
                                                fontWeight: 500,
                                                height: "90px",
                                                textOverflow: "ellipsis",
                                            }}>After Experiencing Sexual Assault, Rearranging My Bed Was More Helpful Than I Expected</div>
                                            <div class="news-row">
                                                <span class="news-sub-head">Apartment Therapy</span>
                                                <span class="news-date">4/27/22</span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-8 text-center py-3">
                                <div class="emailer-text">Our licensed clinical and medical professionals are thought leaders who contribute to both local and national news publications. If you have a media request for this location,
                                    <a href="mailto:marketing@thriveworks.com">email us now.</a>
                                </div>
                            </div>
                        </div>
                        <hr />
                    </div>
                </div>
                </section>
            </section>
            <Footer />

        </div >
    );
};



export default Counselors;