import React from "react";
import Header from "../components/header/header";
import Keyboard from "../../assets/img/keyboard.png"
import Dog from "../../assets/img/dog.png"
import StepThree from "../../assets/img/dummy-img2.jpeg";
import "./aboutdoctors.scss";

const AboutDoctors = () => {
    return (
        <div className="about-doctor">
            <div className="header-about-doc">
                <Header />
            </div>
            <div className="top-banner inside-banner">
                <div className="container">
                    <div className="tb-content">
                        <h2>
                            We make it easy <br />so you can focus on you.
                        </h2>
                    </div>
                </div>
            </div>
            {/* step1 */}
            <div className="container inside-container ins-cont-rt">
                <div className="row clearfix">
                    <div className="col-sm-4  ins-cont-img"><img src={Keyboard} /></div>
                    <div className="col-sm-8 ins-cont-txt">
                        <h3><span className="step">Step 1</span>Sign up to become a VirtualPsych Patient.</h3>
                        <p>It takes just a few minutes and some basic personal information to create an account and register.</p>
                        <a className="button pri-button">Book an Appointment</a>
                    </div>
                </div>
            </div>
            {/*step 2*/}
            <div className="container inside-container ins-cont-lft">
                <div className="row clearfix">
                    <div className="col-sm-4 ins-cont-img"><img src={Dog} /></div>
                    <div className="col-sm-8 ins-cont-txt">
                        <h3><span className="step">Step 2</span>Book an appointment that's convenient for you.</h3>
                        <p>Once you’ve registered, you can choose your first appointment time within as little as 24 hours. </p>
                        <a className="button pri-button" >Book an Appointment</a>
                    </div>
                </div>
            </div>
            {/* step 3 */}
            <div className="container inside-container ins-cont-rt">
                <div className="row clearfix">
                    <div className="col-sm-4  ins-cont-img"><img src={StepThree} /></div>
                    <div className="col-sm-8 ins-cont-txt">
                        <h3><span className="step">Step 3</span>See a provider in as little as one day.</h3>
                        <p>We offer HD video technology so you can virtually connect with your doctor. We’re also happy to schedule
                            phone-only appointments.</p>
                        <a className="button pri-button" >Book an Appointment</a>
                    </div>
                </div>
            </div>
            {/* Comprehensive Mental Health Care */}
            <div className="grey-container preventative-care">
                <div className="container inside-container inside-center">
                    <div className="row" style={{ marginBottom: 20 }}>
                        <div className="col-sm-12">
                            <h3 style={{ marginBottom: 10 }}>Comprehensive Mental Health Care</h3>
                            <p>For your immediate and long-term mental health.</p>
                        </div>
                    </div>
                    <div className="row clearfix">
                        <div className="col-sm-4">
                            <h4>Take a mental health day</h4>
                            <p>VirtualPsych is a completely new approach to mental health care.</p>
                        </div>
                        <div className="col-sm-4">
                            <h4>Get to know our providers</h4>
                            <p>Consistent, compassionate care is available. Our providers strive to provide the best care, communication,
                                and treatment.</p>
                        </div>
                        <div className="col-sm-4">
                            <h4>Talk to us at any time</h4>
                            <p>Text, Email, or Call. We will be there to provide support and assistance when you need it.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutDoctors;