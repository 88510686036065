import Footer from "../components/footer/footer";
import Header from "../components/header/header"
import Locations from "../location/location";
import BannerText from "./bannertext";
import Provider from "./provider";

const ProviderContainer = () => {
    return (
        <div className="provider-container">
            <div className="provider-container-header">
                <Header />
            </div>
            <div className="provider-container-body">
                <BannerText />
                <div className="container">
                    <Locations />
                    <Provider />
                </div>
            </div>
            <Footer />

        </div>
    );
};

export default ProviderContainer;