import React from "react";
import "./home-component.scss";

const UpcomingAppointment = () => {
    return (
        <div className="home-component">
            <section className="book-appointment" style={{backgroundColor: "#F7F5F3"}}>
                <div className="container">
                    <div className="row">

                        <div className="col-md-6 mt-5 ">
                            <div className="plan-box">
                                <div className="plan-header  plan-card">
                                    <div className="plan-icon">
                                    </div>
                                    <h3>Upcoming appointment</h3><i className="fal fa-stethoscope"></i>
                                    <h4> you have schedule appointment</h4>
                                </div>

                                <div className="plan-button mb-3 text-center">
                                    <a href="Individual-doctor-details.html" className="plan-btn">Book Now</a>
                                </div>
                            </div>
                        </div>



                        <div className="col-md-6 mt-5">
                            <div className="plan-box">
                                <div className="plan-header">
                                    <div className="plan-icon">
                                    </div>
                                    <h3>Primary Care Physician</h3>
                                </div>

                                <div className="plan-body">
                                    <ul>
                                        <li><i className="fal fa-check-circle"></i>Easily book follow up appointment</li>
                                        <li><i className="fal fa-check-circle"></i>Massages your physician and care team</li>
                                        <li><i className="fal fa-check-circle"></i>Receive regular and continued care</li>
                                    </ul>
                                    <p>When you add a primary care physician you'll still have access to our whole networking
                                        physician </p>
                                </div>
                                <div className="plan-button mb-3 text-center plan-button-1">
                                    <a href="Individual-doctor-details.html" className=" physician-btn">Choose Now</a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="health-appointment" style={{ backgroundColor: "#F7F5F3" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="health-box">
                                <div className="health-icon">
                                    <i className="fal fa-history"></i>
                                </div>
                                <div className="health-description">
                                    <h4>Visit History</h4>
                                    <p>0 Past Encounters </p>
                                </div>


                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="health-box">
                                <div className="health-icon">
                                    <i className="fas fa-capsules"></i>
                                </div>
                                <div className="health-description">
                                    <h4>Prescriptions</h4>
                                    <p>0 Prescriptions</p>
                                </div>


                            </div>
                        </div>


                        <div className="col-md-3">
                            <div className="health-box">
                                <div className="health-icon">
                                    <i className="fas fa-flask"></i>
                                </div>
                                <div className="health-description">
                                    <h4>Lab Results</h4>
                                    <p>0 Results</p>
                                </div>


                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="health-box">
                                <div className="health-icon">
                                    <i className="fas fa-notes-medical"></i>
                                </div>
                                <div className="health-description">
                                    <h4>Your Health</h4>
                                    <p>visit body and more</p>
                                </div>


                            </div>
                        </div>
                    </div>

                </div>
            </section>

        </div>
    )
}

export default UpcomingAppointment;