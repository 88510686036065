import { useState } from 'react';


function useScheduleAppointmentHook() {
    const [IsNewPatientClicked, setIsNewPatientClicked] = useState(false);
    
    const handleNewPatient = () => {
        setIsNewPatientClicked(true)
    }


    return {
        IsNewPatientClicked,
        setIsNewPatientClicked,
        handleNewPatient


    };
}

export default useScheduleAppointmentHook;
