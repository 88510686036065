import React from "react";
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import "./termsCondition.scss";
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';
import Button from '@mui/material/Button';
import HailOutlinedIcon from '@mui/icons-material/HailOutlined';
import Checkbox from '@mui/material/Checkbox';
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const TermsCondition = () => {
    return (
        <div className="terms-condition">
            <div className="terms-condition-header">
                <Header />
            </div>
            <div className="terms-condition-container">
                <h4 className="service"><HailOutlinedIcon />&nbsp;&nbsp;WHO MAY USE THIS SERVICE.</h4>
                <div className="para">
                    <p className="use-services">when one door of happiness, closes, another opens, but often we look so long at the closed door that
                        we dont see the one that has been opend for us
                    </p>
                    <p>
                        <b><b className="dot">.</b>&nbsp;&nbsp;&nbsp;&nbsp;Step 1:&nbsp;&nbsp;</b>
                        You may use the services only if you agree to form a binding contact with us and are not a person barred fomr receving services under the laws of the applicable jurisdiction
                    </p>
                    <p>
                        <b><b className="dot">.</b>&nbsp;&nbsp;&nbsp;&nbsp;Step 2:&nbsp;&nbsp;</b>
                        Our Privacy Policy describes how we handle the information you provide to us when you use our services.
                    </p>
                    <h4 className="privacy"><ShieldOutlinedIcon />&nbsp;&nbsp;PRIVACY</h4>
                    <p className="privacy-para">when one door of happiness, closes, another opens, but often we look so long at the closed door that
                        we dont see the one that has been opend for us</p>
                    <div className="agree">
                        <Checkbox {...label} defaultChecked />
                        <Button variant="contained" disableElevation>
                            AGREE & CONTINUE
                        </Button>
                    </div>
                </div>

            </div>
            <Footer />
        </div>
    )
};

export default TermsCondition;