import { useState, useEffect } from 'react';
import { contactService } from './contactService';
import { errorToast, successToast } from '../components/toastmessage/toastmessage';

function useContactUsHook(initialState) {
    const [formState, setFormState] = useState(initialState);
    const [formErrors, setFormErrors] = useState({});
    const [isFormFilled, setIsFormFilled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    // const [IsValidEmail, setIsValidEmail] = useState(true);
    const [isValidEmail, setIValidEmail] = useState(false);

    useEffect(() => {
        // Check if the form is filled (has no empty fields)
        const filled = Object.values(formState).every((value) => value.trim() !== '');
        setIsFormFilled(filled);
    }, [formState]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormState((prevFormState) => ({
            ...prevFormState,
            [name]: value,
        }));
    };

    const isEmailValid = (email) => {
        return /^\S+@\S+\.\S+$/.test(email);
    };

    const handleBlur = (event, fieldName, errorType) => {
        const { name, value } = event.target;
        const errors = {};
        if (name === fieldName && value.trim() === '') {
            errors[fieldName] = `${fieldName.charAt(0).toUpperCase() + errorType.slice(1)} is required`;
        }
        if (name === 'email' && !isEmailValid(value)) {
            errors.email = 'Invalid email address';
           
        }
        else{
            let val = isEmailValid(value)
            console.log(!val, "val")
           
        }

        setFormErrors((prevFormErrors) => ({
            ...prevFormErrors,
            ...errors,
        }));
       

    };

    useEffect(() => {
        // Regular expression for email validation
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setIValidEmail(emailPattern.test(formState?.email));
      }, [formState?.email]);
    
    const handleSubmit = async (event) => {
        event.preventDefault();
            try {
                setIsLoading(true)
                let result = await contactService(formState);
                successToast("Successfully Created");
                setFormState(initialState);
                setIsLoading(false)
                // eslint-disable-next-line
                console.log(result, "result")

            } catch (error) {
                setIsLoading(false)
                errorToast(error?.message)
                // eslint-disable-next-line
                console.error('error ', error);
            }


    };

    const onNumberOnlyChange = (e) => {
        var code = (e.which) ? e.which : e.keyCode;
        if (code > 31 && (code < 48 || code > 57)) {
            e.preventDefault();
        }
    }

    return {
        formState,
        formErrors,
        handleChange,
        handleBlur,
        handleSubmit,
        isFormFilled,
        isLoading,
        onNumberOnlyChange,
        isValidEmail

    };
}

export default useContactUsHook;
