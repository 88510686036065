import {  useNavigate } from "react-router-dom";
import "./provider.scss";

const Provider = () => {
    const navigate = useNavigate()

    const navigatLocation = () => {
        // navigate("/locations")
    }
    return (
        <div className="provider-container">
            <div className="provider-box">
                <div className="header-text mt-5">
                    <h2 className="h2-text">Live better with the help of a therapist<br />
                        <center>or psychiatrist</center>
                    </h2>
                    <p>Browse-top-rated provider near you, and find one who meets your need. WhoAreTheDoctors
                        <br /><center>accept most insurance, and offer weekend and evening sessions</center>
                    </p>
                    <div className="prpvider-btns">
                        <button type="button" className="btn btn-warning btns-calls" onClick={navigatLocation}>Find aprovider</button>
                        <button type="button" className="btn btn-primary btns-calls btn-call">Call: 877-850-8075</button>
                    </div>
                    <div className="star-rating">
                        <label for="star5" title="5 stars">&#9733;</label>
                        <label for="star4" title="4 stars">&#9733;</label>
                        <label for="star3" title="3 stars">&#9733;</label>
                        <label for="star2" title="2 stars">&#9733;</label>
                        <label for="star1" title="1 star">&#9733;</label>
                    </div>
                    <p className="rating">Rated highly overall from thousands of reviews</p>
                </div>

            </div>
        </div>
    );
};

export default Provider;