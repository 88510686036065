import "./bannertext.scss";

const BannerText = () => {
    return (
        <div className="banner-text-container">
            <div className="container">
                <div className="row align-items-cener banner-body">
                    <div className="col-12 col-md-6">
                        <h1>Find your way to a Virtulpsych practice near you</h1>
                        <p>
                            <a href="#" className="find-counselor-cta-book-btn hero V1_hero_same_copy">Find a provider</a>
                        </p>
                    </div>
                    <div className="col-12 col-md-6">
                        {/* <h1>Find your way to a Thriveworks practice near you</h1> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BannerText;