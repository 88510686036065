import React from "react";
import Twitter from "../../../assets/img/twitter-icon.png";
import Insta from "../../../assets/img/insta-icon.png";
import LinkedIn from "../../../assets/img/linkedin-icon.png";
import YouTube from "../../../assets/img/youtube-icon.png";
import Facebook from "../../../assets/img/facebook-icon.png"
import "./footer.scss";
const Footer  = () => {
    return(
        <>
        <footer className="main-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-4">
                            <ul className="ul-list">
                                <li><strong>LEARN MORE</strong></li>
                                <li><a href="#">About VirtualPsych Health</a></li>
                                <li><a href="#">Blog</a></li>
                                <li><a href="https://angel.co/jobs">Careers</a></li>
                                <li><a href="#">Newsroom</a></li>
                            </ul>
                        </div>
                        <div className="col-sm-4">
                            <ul>
                                <li><strong>SOLUTIONS</strong></li>
                                <li><a href="#">VirtualPsych for Businesses</a></li>
                                <li><a href="#">VirtualPsych for Health Systems</a></li>
                            </ul>
                        </div>
                        <div className="col-sm-4">
                            <ul>
                                <li><strong>CONNECT WITH US</strong></li>
                                <li><a href="#">Become a Provider</a></li>
                                <li><a href="/contact-us">Contact Us</a></li>
                                <li><a href="#">Request a Demo</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="social-sec">
                    <a href="https://www.facebook.com/VirtualPsychFB/" className="social-icon"><img src={Facebook} alt="" /></a>
                    <a href="#" className="social-icon"><img src={Twitter} alt="" /></a>
                    <a href="#" className="social-icon"><img src={Insta} alt="" /></a>
                    <a href="#" className="social-icon"><img src={LinkedIn} alt="" /></a>
                    <a href="#" className="social-icon"><img src={YouTube} alt="" /></a>
                </div>
            </footer>
            <div className="privacy-sec">
                <div className="container">
                    <a href="#">Privacy Policy</a>
                    <a href="/terms-condition">Terms & Conditions</a>
                    <a href="#">Notice of Non-Discrimination</a>
                </div>
            </div>
        </>

    );
}
export default Footer