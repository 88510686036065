import React from "react";
import Header from "../components/header/header";
import Doc from "../../assets/img/demo-doc-image.png";
import IOS from "../../assets/img/ios-btn.png";
import Andriod from "../../assets/img/android-btn.png";
import Footer from "../components/footer/footer";
import "./whoAreTheDoctors.scss";
const WhoAreTheDoctors = () => {
    return (
        <div className="who-are-the-doc">
            <div className="who-are-the-doc-header-about-doc">
                <Header />
            </div>
            {/* banner image */}
            <div className="top-banner inside-banner">
                <div className="container">
                    <div className="tb-content">
                        <h2>
                            A Real Two-Way Relationship with Your Doctor, Completely Online
                        </h2>

                    </div>
                </div>
            </div>
            {/* quality care */}
            <div className="container inside-container ins-cont-lft">
                <div className="row clearfix">
                    <div className="col-sm-4  ins-cont-img"><img src={Doc} /></div>
                    <div className="col-sm-8 ins-cont-txt">
                        <h3>Quality Care. The Time & Attention You Deserve.</h3>
                        <p>VirtualPsych provides intake assessments if you are not quite sure what type of telepsych healthcare you need.
                            After, follow-up appointments can be made to address your specific concerns. Your input helps get you on the
                            road to better mental health. Your input is important - we take the time to listen to your concerns, ask
                            pertinent questions and put it together to develop a treatment approach. Appointments using phone or video chat
                            afford you the privacy you want without the need for an in-office visit. Telepsych visits can help relieve the
                            anxiety associated with going to the doctor. Being in the comfort of your home can prove valuable in discussing
                            your mental health concerns without the feeling of being rushed, which often occurs in traditional settings.</p>

                        <p>VirtualPsych is a completely new approach to mental health care. Our doctors see a limited number of patients,
                            so they can give you the time and attention you deserve. Our service is completely online, which means that you
                            get comprehensive, personalized care from anywhere in the world, on any device. Your VirtualPsych doctor is your
                            partner in mental health, long-term.</p>
                    </div>
                </div>
            </div>
            {/* psychiatric care */}
            <div className="grey-container preventative-care">
                <div className="container inside-container inside-center">
                    <div className="row" style={{ marginBottom: 20 }}>
                        <div className="col-sm-12">
                            <h3 style={{ marginBottom: 10 }}>Virtual Psychiatric Care</h3>
                            <p>Personalized care for your long-term behavioral health.</p>
                        </div>
                    </div>
                    <div className="row clearfix">
                        <div className="col-sm-4">
                            <h4>VirtualPsych books fast</h4>
                            <p>We have appointments available daily, and the time to see a physician is typically less than a few days.</p>
                        </div>
                        <div className="col-sm-4">
                            <h4>100% Virtual Appointments</h4>
                            <p>Appointments using phone or video chat afford you the privacy you want without the need for an in-office
                                visit.</p>
                        </div>
                        <div className="col-sm-4">
                            <h4>VirtualPsych listens</h4>
                            <p>Our providers listen, your concerns and input helps us get you on the road to better mental health.</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* download ap banner */}
            <div className="app-bx">
                <div className="app-banner"> </div>
                <div className="container">
                    <div className="app-bnr-cntnt">
                        <h1>Care On The GO</h1>
                        <h4>Download Mobile App</h4>
                        <p>Our mobile app is coming soon! <br />please
                            check in for app release updates.</p>
                        <div className="app-download-link">
                            <a href=""><img src={IOS} alt="" /></a>
                            <a href=""><img src={Andriod} alt="" /></a>
                        </div>
                    </div>
                </div>
            </div>
            {/* footer */}
            <Footer />
        </div>
    );
};

export default WhoAreTheDoctors;