import React, { useState } from "react";
import { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import { useNavigate, NavLink } from "react-router-dom";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import "./memberlogin.scss";
Auth.configure(awsconfig);


const MemberLogin = () => {
    const navigate = useNavigate();
    const [inputValues, setInputValues] = useState({
        username: '', password: ''
    });
    const handleOnChange = event => {
        const { name, value } = event.target;
        setInputValues({ ...inputValues, [name]: value });
    };
    async function signIn(event) {
        event.preventDefault();
        try {
            const user = await Auth.signIn(inputValues?.username, inputValues?.password);
            if (user?.userDataKey) {
                localStorage.setItem("Session", user?.signInUserSession?.accessToken?.jwtToken);
                navigate("/upcoming-appointment")
            }
        } catch (error) {
            console.error('error signing in', error);
        }
    }

    const handleRegister = () => {
        navigate("/register")
    }

    return (
        <div className="member-login">
            <div className="memeber-login-header">
                <Header />
            </div>
            {/* login form */}
            <div id="section3">
                <section id="testimornial-area">
                    <div className="container card main-login-bx">
                        <div className="row">
                            <div className="col-md-6 log-reg-form">
                                <h2>Member/Patient Login</h2>
                                <h5>Access board-certified doctors 24/7/365</h5>
                                <form>
                                    <div className="form-group">
                                        <label >Email</label>
                                        <input id="email" type="text" required name="username" className="form-control" placeholder="Email" onChange={handleOnChange} value={inputValues?.username} />
                                    </div>
                                    <div className="form-group">
                                        <label >Password</label>
                                        <input type="password" className="form-control" id="password" required name="password" placeholder="Password" onChange={handleOnChange} value={inputValues?.password} />
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary" onClick={signIn} disabled={inputValues?.username?.length > 0 & inputValues?.password?.length > 0 ? false : true}>Login</button>
                                    </div>
                                    <div className="alert alert-success" role="alert">
                                        <strong>Are you new!</strong> Please setup your account.
                                        <NavLink className="reg" to="/register" onClick={handleRegister}>Register</NavLink>
                                        {/* <a className="reg" >Register</a> */}
                                    </div >
                                </form >
                            </div >
                            <div className="col-md-6 log-reg-img login-image"></div>
                        </div >
                    </div >
                </section >
            </div >
            {/* footer */}
            <Footer />
        </div>
    );
};


export default MemberLogin;