import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import ScheduleDoctorAppointments from './scheduleDoctorAppointment';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}


export default function RenderTabs(props) {
    const { tabContent, tabIndex, tabLabelsName } = props
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ bgcolor: 'background.paper' }}>
            <Tabs
                value={tabIndex ? tabIndex : value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                TabIndicatorProps={{
                    style: {
                        backgroundColor: "#f26b2e",
                        color: "#000"
                    }
                }}

            >
                {tabLabelsName.map((names, index) => {
                    return (
                        <Tab
                            key={index}
                            label={names}
                            className={`tab-${index} font-weight-bold`}
                        />
                    );
                })}

            </Tabs>
            <TabPanel>
                <ScheduleDoctorAppointments />
            </TabPanel>
       
        </Box>
    );
}
